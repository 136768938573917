<template>
  <div style="height: 60vh; overflow-y: auto">
    <div class="card card-custom gutter-b" style="height: 60vh; overflow-y: auto">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Report By Locations:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
        <div class="card-toolbar">
          <button
            @click.prevent="exportAsExcel"
            class="btn btn-light-primary font-weight-bold px-10"
          >
            Export .xlsx
          </button>
        </div>
      </div>
      <div class="card-body pt-0">
        <DeviceReportTable
          v-if="isGenerated"
          :traffic="traffic"
          :selectedDevices="selected"
          :isTokenColumnVisible="true"
        ></DeviceReportTable>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapGetters } from 'vuex'
import axios from 'axios'
import DeviceReportTable from '../../../content/components/device-report-table/DeviceReportTable'

export default {
  name: 'LessorTraffic',
  props: {
    traffic: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  components: {
    DeviceReportTable,
  },
  data() {
    return {
      isGenerated: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    setTimeout(() => {
      this.isGenerated = true
    }, 500)
  },
  methods: {
    exportAsExcel() {
      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      let temLocationsIds = ''
      this.selected.forEach((location) => {
        let l = location['@id'].replace('/api/v1/devices/', '')
        temLocationsIds += l + ','
      })

      axios
        .get(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/devices-revenue/${this.currentUserCompanyOib}/${temLocationsIds}/${from}/${end}`
        )
        .then(({ data }) => {
          if (data.status === 'ok') {
            window.location.replace(data.fileUrl)
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Export success',
            })
          }
        })
    },
  },
}
</script>
